<template>
  <div v-if="fields != false">
    <Datatable ref="liste_mutuelles" route="/mutuelle/liste" :fields="fields" :data_filters="filters" titre="Liste des mutuelles" :sort="sort">      
      <template #action>
        <b-button v-b-tooltip.hover title="Creer une nouvelle mutuelle" variant="success" class="btn-icon" v-b-modal.modal_mutuelle><i class="fas fa-plus"></i></b-button>
      </template>
      <template #cell()="data">
        <Input :type="data.field.type" v-model="data.item[data.field.key]" />
      </template>            
      <template #cell(action)="data">
        <b-button-group>
          <b-button variant="success" class="btn-icon" size="sm" @click="save(data.item)"><i class="fa fa-save"></i></b-button>
          <b-button variant="danger" class="btn-icon" size="sm" @click="data.item.status = 0 ;save(data.item)"><i class="fa fa-trash"></i></b-button>
        </b-button-group>
      </template>            
    </Datatable>
    <b-modal id="modal_mutuelle" ref="modal_mutuelle" hide-footer>
      <b-row>
        <b-col md="6" v-for="(f, i) in fields.filter(f => f.key != 'action')" :key="i">
          <b-form-group :label="f.label">
            <Input :type="f.type" v-model="tmp[f.key]" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-block text-center mt-5">
        <b-button variant="outline-primary" class="btn-icon" @click="save(tmp)"><i class="fa fa-save"></i></b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
import Input from '@/components/InputForm';
export default {
  name: "MutuelleListe",
  components: {
    Datatable,
    Input
  },
  watch:{    
  },
  data(){
    return{
      filters:{},
      fields: false,      
      sort:{
        key: 'id',
        value: 'DESC'
      },
      tmp:{}
    }
  },
  computed:{
  },
  methods: {
    save(item){
      this.$api.ajax('/mutuelle/create', item, res => {
        if(res.status === true){
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
          this.$refs.liste_mutuelles.datatableInit();
          this.tmp = {};
          this.$refs.modal_mutuelle.hide();
        }
      })
    }
  },
  beforeMount() {
    var params = {
      filters: {},
      sort:{
        key: "id",
        value: "ASC"
      },
      limit:1,
      page:1
    }
    this.$api.ajax('/mutuelle/liste', params, res => {      
      this.fields = res.meta.fields;
      this.fields.push({
        key:'action',
        label:"Action",        
      })
    })
  },
  mounted() {    
  }
}
</script>
<style>
</style>
